import React from 'react';
import * as Icon from 'react-bootstrap-icons';


function LastSeen(props) {
    let tstamp = props.tstamp;
    const tnow = Date.now();

    if (tstamp !== undefined && tstamp !== null && isNaN(tstamp) === false && tstamp !== 0) {
        tstamp = new Date(tstamp * 1000);
        const delta = (tnow - tstamp) / 1000;
        const days = Math.floor(delta / 86400);
        const hours = Math.floor((delta - (days * 86400)) / 3600)
        return (
            <div>{days} days {hours} hours </div>
        )
    } else {
        return (<div>First seen</div>)
    }
}


export class Flashcard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showENword: props.showENword,
            showPLword: props.showPLword,
            showButtons: props.showButtons
        }
    }

    componentDidMount() {
        this.api = this.props.api;
    }

    handleUpgradeLevel = () => {
        this.props.addOkPoints();
        this.props.okCallback();

    }

    handleDowngradeLevel = () => {
        this.props.addBadPoints();
        this.props.badCallback();

    }

    handlePronounciacionEN = (event, id) => {
        this.api.getPronounciacionEN(
            id,
            (data) => {
                let audio = new Audio(data.url);
                audio.play();
            },
            (err) => {
                alert(err);
            }
        )
    }

    render = () => {
        if (this.props.flashcard === null) {
            return false
        } else {

            let buttons = null;
            if (this.state.showButtons === true && this.state.showPLword === true && this.state.showENword === true) {
                buttons = (
                    <div className='row justify-content-center'>
                        <div className='col'>

                            <div className="d-flex justify-content-evenly my-2">
                                <div
                                    className="btn btn-sm btn-success rounded-3 px-5"
                                    onClick={event => { this.handleUpgradeLevel() }}
                                >
                                    <Icon.CheckSquare size={25} />
                                </div>
                                <div
                                    className="btn btn-sm btn-danger rounded-3 px-5"
                                    onClick={event => { this.handleDowngradeLevel() }}
                                >
                                    <Icon.XSquare size={25} />
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }

            let pl = null;
            let examples_pl = null;
            if (this.state.showPLword === true) {
                pl = (<h5> {this.props.flashcard.pl} </h5>)
                if (this.props.flashcard.example_pl !== null && this.props.flashcard.example_pl.length > 0) {
                    examples_pl = (
                        <ol className='examples text-start fst-italic lh-2 font-monospace'>
                            {this.props.flashcard.example_pl.map((item, index) => {
                                return <li key={index}>{item}</li>
                            })}
                        </ol>
                    )
                }
            } else {
                pl = (
                    <div
                        className='btn btn-sm btn-secondary px-5 rounded-3'
                        onClick={envent => { this.setState({ showPLword: true }) }}
                    >
                        <Icon.QuestionDiamond size={25} />
                    </div>
                )
            }


            let en = null;
            let examples_en = null;
            if (this.state.showENword === true) {
                en = (
                    <div>
                        <h5>
                            {this.props.flashcard.en}
                        </h5>
                        {this.props.flashcard.pronunciation !== "" && this.props.flashcard.pronunciation !== null &&
                            <span className='pronunciation fst-italic'>[{this.props.flashcard.pronunciation}]</span>
                        }
                        <Icon.Megaphone
                            className='ms-3 text-secondary pb-2'
                            size={26}
                            onClick={event => this.handlePronounciacionEN(event, this.props.flashcard.id)}
                        />
                    </div>
                )
                if (this.props.flashcard.example_en !== null && this.props.flashcard.example_en.length > 0) {
                    examples_en = (
                        <ol className='examples text-start fst-italic lh-2 font-monospace'>
                            {this.props.flashcard.example_en.map((item, index) => {
                                return <li key={index}>{item}</li>
                            })}
                        </ol>
                    )
                }
            } else {
                en = (
                    <div
                        className='btn btn-sm btn-secondary px-5 rounded-3'
                        onClick={envent => { this.setState({ showENword: true }) }}
                    >
                        <Icon.QuestionDiamond size={25} />
                    </div>
                )
            }

            return (
                <div className="border border-light border-2 rounded-3 mx-auto mt-4 p-0 pt-4 position-relative text-center text-white">

                    <span className="badge rounded-3 bg-light text-dark position-absolute top-0 start-0 translate-middle-y fs-4">
                        {this.props.flashcard.level}
                    </span>

                    <span className="badge rounded-3 bg-light text-dark position-absolute top-0 end-0 translate-middle-y fs-6">
                        <LastSeen tstamp={this.props.flashcard.tstamp} />
                    </span>


                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-2 text-left">[PL]</div>
                            <div className="col-10 h5">{pl}</div>
                        </div>
                        {examples_pl}

                        <div className="row border-top pt-3 mb-2">
                            <div className="col-2 text-left">[EN]</div>
                            <div className="col-10 h5">{en}</div>
                        </div>
                        {examples_en}

                    </div>

                    {buttons}

                </div>
            )
        }
    }
}
